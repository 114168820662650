import useSegment from "~/hooks/use-segment";
import useRequest from "~/hooks/use-request";
import { formatCustomerSubscriptionItem, formatSubscriptionItem } from "~/services/format/subscription";
import { formatPaginatedResponseItems, formatRequestOptions } from "~/services/format/";

const SubscriptionAPI = {
  load(): Promise<IPaginatedResponse<IModelCommerce>> {
    const { request } = useRequest();
    const queryParams = { type: "svod|cvod" };

    return request
      .get<IPaginatedResponse<IResponseSubscription>>("/api/commerce/get_subscriptions", { query: queryParams })
      .then(response => formatPaginatedResponseItems(response, formatSubscriptionItem));
  },

  loadById(id: string): Promise<IModelCommerce> {
    const { request } = useRequest();

    return request
      .get<IContentResponse<IResponseSubscription>>(`/api/commerce/get_subscriptions_by_id/id/${id}`)
      .then(response => formatSubscriptionItem(response.content));
  },

  loadUserSubscriptions(options?: IRequestPaginatedOptions, active?: boolean): Promise<IPaginatedResponse<IModelCommerce>> {
    const { request } = useRequest();
    const generalParams = formatRequestOptions(options);
    const params = { ...generalParams, ...active && { active: true, canceled: false } };

    return request
      .get<IPaginatedResponse<IResponseCustomerSubscription>>("/api/commerce/customer_contracts", { query: params })
      .then(response => formatPaginatedResponseItems(response, formatCustomerSubscriptionItem));
  },

  loadUserSubscriptionById(id: string): Promise<IModelCommerce> {
    const { request } = useRequest();

    return request
      .get<IContentResponse<IResponseCustomerSubscription>>(`/api/commerce/get_customer_subscriptions_by_id/id/${id}`)
      .then(response => formatCustomerSubscriptionItem(response.content));
  },

  cancelUserSubscription(subscriptionId: string): Promise<void> {
    const segment = useSegment();
    const { request } = useRequest();
    const payload = { platform: "web", id: subscriptionId };

    return request
      .post<IContentResponse<IResponseCustomerSubscription>>("/api/commerce/cancel_contract", { body: payload })
      .then(() => segment.track("Subscription canceled ", payload));
  },

  subscribeWithOwnCreditCard(subscriptionId: string): Promise<void> {
    const segment = useSegment();
    const { request } = useRequest();
    const payload = { subscription_id: subscriptionId };

    return request
      .post("/api/commerce/subscribe_by_stored_cc", { body: payload })
      .then(() => segment.track("Subscription Purchase ", payload));
  },

  subscribeWithCreditCard(subscriptionId: string, tokenID: string): Promise<void> {
    const segment = useSegment();
    const { request } = useRequest();
    const payload = {
      card: { token: tokenID },
      subscription_id: subscriptionId,
    };

    return request
      .post("/api/commerce/subscription_checkout", { body: payload })
      .then(() => segment.track("Subscription Purchase ", subscriptionId));
  },

  loadVodCommerceOptions(eventId: string): Promise<IModelCommerce[]> {
    const { request } = useRequest();
    return request
      .get<IContentArrayResponse<IResponseSubscription>>(`/api/event/get_commerce_options?eventId=${eventId}`)
      .then(response => response.content.map(formatSubscriptionItem));
  },
};

export default SubscriptionAPI;
