import { default as aboutdraYrkEObeMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/about.vue?macro=true";
import { default as editjEsoGGYQ6YMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing/edit.vue?macro=true";
import { default as indexFyGXmGw6gEMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing/index.vue?macro=true";
import { default as billingMOIL4gBFEVMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing.vue?macro=true";
import { default as devicesOMQPR2ZQ3RMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/devices.vue?macro=true";
import { default as generaluWxV1h8FsgMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/general.vue?macro=true";
import { default as indexRtHgYQnpE8Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/index.vue?macro=true";
import { default as passwordCPEYbKiLtQMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/password.vue?macro=true";
import { default as _91id_93zlbohoMXieMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/[id].vue?macro=true";
import { default as indextZO1FBVubNMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/index.vue?macro=true";
import { default as ppvpFhmcUaNQuMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv.vue?macro=true";
import { default as _91id_9346ihf0RgdGMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/[id].vue?macro=true";
import { default as _91id_93aoKZ3MYhPXMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/buy/[id].vue?macro=true";
import { default as indexGncTrZ6I8mMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/index.vue?macro=true";
import { default as subscriptionGK0H4nT9kSMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription.vue?macro=true";
import { default as account3QhAHyLpsTMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account.vue?macro=true";
import { default as archiveagv6Md1HtmMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/archive.vue?macro=true";
import { default as indexyCZSksEIAdMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/index.vue?macro=true";
import { default as limitNe94pxrS4sMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/limit.vue?macro=true";
import { default as _91provider_93KO9aHETKvqMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/oauth2/callback/[provider].vue?macro=true";
import { default as forgotPhraeHcXZBMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/forgot.vue?macro=true";
import { default as resetZUtNOoQCvAMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/reset.vue?macro=true";
import { default as indexerWbk1cPT1Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/index.vue?macro=true";
import { default as localqFLW4jpcsnMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/local.vue?macro=true";
import { default as _91id_93sf6X36NXx7Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/buy/[id].vue?macro=true";
import { default as indexGzyaFp4lDlMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/code/index.vue?macro=true";
import { default as indexzheoBa6kytMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/index.vue?macro=true";
import { default as indexzFgmX1hiL4Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/index.vue?macro=true";
import { default as _91subId_93boNKH4MYXtMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/sub-category/[subId].vue?macro=true";
import { default as _91eventId_93hpmr16FfV6Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/channels/[eventId].vue?macro=true";
import { default as clubp6uHnNJs0CMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/club.vue?macro=true";
import { default as _91eventId_93Gt52yYUXLsMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/[eventId].vue?macro=true";
import { default as _91eventId_93RH99X71Gi6Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/stats/[eventId].vue?macro=true";
import { default as faqGSte9SsT6iMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/faq.vue?macro=true";
import { default as followedVWOSJWEFvUMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/followed.vue?macro=true";
import { default as indextSbAy0AAqeMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/index.vue?macro=true";
import { default as followedmn0BD1umiyMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/followed.vue?macro=true";
import { default as indexnIsWNkWcJ9Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/index.vue?macro=true";
import { default as indexBfqRWuY7wOMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/index.vue?macro=true";
import { default as followedqBvXcSKB5HMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/followed.vue?macro=true";
import { default as indexvQrLsgg37KMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/index.vue?macro=true";
import { default as followedFOB5uix5euMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/followed.vue?macro=true";
import { default as indexNNtfK3bXBTMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/index.vue?macro=true";
import { default as highlightQbdm5f6gTbMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/highlight.vue?macro=true";
import { default as homeemZ3sW7FtDMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/home.vue?macro=true";
import { default as indexKb1RKZykycMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/index.vue?macro=true";
import { default as _91type_93111hFqEuygMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/info/[type].vue?macro=true";
import { default as latestNK3GtIVxiZMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/latest.vue?macro=true";
import { default as _91leagueId_93WP3xOzdbC0Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/league/[leagueId].vue?macro=true";
import { default as liveNtVokPeuMlMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live.vue?macro=true";
import { default as open_45appI3ro9r9FlcMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/open-app.vue?macro=true";
import { default as failureWMQwTcioRmMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/failure.vue?macro=true";
import { default as processing3kizEWGEOlMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/processing.vue?macro=true";
import { default as successt5K9xuFKVVMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/success.vue?macro=true";
import { default as privacyXCV1lppPu4Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/privacy.vue?macro=true";
import { default as recommendationBzugptqcd5Meta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/recommendation.vue?macro=true";
import { default as _91referenceId_934RS158WUVoMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/redirect/events/[referenceId].vue?macro=true";
import { default as search1eBKK12ZypMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/search.vue?macro=true";
import { default as clips8xi9yJalyIMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/clips.vue?macro=true";
import { default as videosdTfMKbypxjMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/videos.vue?macro=true";
import { default as suggestedkD9v2Gz7UaMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/suggested.vue?macro=true";
import { default as _91teamId_93StowxngtjOMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/team/[teamId].vue?macro=true";
import { default as termsb3M4kNwponMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/terms.vue?macro=true";
import { default as upcomingwmoZ03iIERMeta } from "/home/runner/work/ott-web-portal2/ott-web-portal2/pages/upcoming.vue?macro=true";
export default [
  {
    name: aboutdraYrkEObeMeta?.name ?? "about",
    path: aboutdraYrkEObeMeta?.path ?? "/about",
    meta: aboutdraYrkEObeMeta || {},
    alias: aboutdraYrkEObeMeta?.alias || [],
    redirect: aboutdraYrkEObeMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/about.vue").then(m => m.default || m)
  },
  {
    name: account3QhAHyLpsTMeta?.name ?? undefined,
    path: account3QhAHyLpsTMeta?.path ?? "/account",
    meta: account3QhAHyLpsTMeta || {},
    alias: account3QhAHyLpsTMeta?.alias || [],
    redirect: account3QhAHyLpsTMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: billingMOIL4gBFEVMeta?.name ?? undefined,
    path: billingMOIL4gBFEVMeta?.path ?? "billing",
    meta: billingMOIL4gBFEVMeta || {},
    alias: billingMOIL4gBFEVMeta?.alias || [],
    redirect: billingMOIL4gBFEVMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing.vue").then(m => m.default || m),
    children: [
  {
    name: editjEsoGGYQ6YMeta?.name ?? "account-billing-edit",
    path: editjEsoGGYQ6YMeta?.path ?? "edit",
    meta: editjEsoGGYQ6YMeta || {},
    alias: editjEsoGGYQ6YMeta?.alias || [],
    redirect: editjEsoGGYQ6YMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing/edit.vue").then(m => m.default || m)
  },
  {
    name: indexFyGXmGw6gEMeta?.name ?? "account-billing",
    path: indexFyGXmGw6gEMeta?.path ?? "",
    meta: indexFyGXmGw6gEMeta || {},
    alias: indexFyGXmGw6gEMeta?.alias || [],
    redirect: indexFyGXmGw6gEMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/billing/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: devicesOMQPR2ZQ3RMeta?.name ?? "account-devices",
    path: devicesOMQPR2ZQ3RMeta?.path ?? "devices",
    meta: devicesOMQPR2ZQ3RMeta || {},
    alias: devicesOMQPR2ZQ3RMeta?.alias || [],
    redirect: devicesOMQPR2ZQ3RMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/devices.vue").then(m => m.default || m)
  },
  {
    name: generaluWxV1h8FsgMeta?.name ?? "account-general",
    path: generaluWxV1h8FsgMeta?.path ?? "general",
    meta: generaluWxV1h8FsgMeta || {},
    alias: generaluWxV1h8FsgMeta?.alias || [],
    redirect: generaluWxV1h8FsgMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/general.vue").then(m => m.default || m)
  },
  {
    name: indexRtHgYQnpE8Meta?.name ?? "account",
    path: indexRtHgYQnpE8Meta?.path ?? "",
    meta: indexRtHgYQnpE8Meta || {},
    alias: indexRtHgYQnpE8Meta?.alias || [],
    redirect: indexRtHgYQnpE8Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: passwordCPEYbKiLtQMeta?.name ?? "account-password",
    path: passwordCPEYbKiLtQMeta?.path ?? "password",
    meta: passwordCPEYbKiLtQMeta || {},
    alias: passwordCPEYbKiLtQMeta?.alias || [],
    redirect: passwordCPEYbKiLtQMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: ppvpFhmcUaNQuMeta?.name ?? undefined,
    path: ppvpFhmcUaNQuMeta?.path ?? "ppv",
    meta: ppvpFhmcUaNQuMeta || {},
    alias: ppvpFhmcUaNQuMeta?.alias || [],
    redirect: ppvpFhmcUaNQuMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93zlbohoMXieMeta?.name ?? "account-ppv-id",
    path: _91id_93zlbohoMXieMeta?.path ?? ":id()",
    meta: _91id_93zlbohoMXieMeta || {},
    alias: _91id_93zlbohoMXieMeta?.alias || [],
    redirect: _91id_93zlbohoMXieMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/[id].vue").then(m => m.default || m)
  },
  {
    name: indextZO1FBVubNMeta?.name ?? "account-ppv",
    path: indextZO1FBVubNMeta?.path ?? "",
    meta: indextZO1FBVubNMeta || {},
    alias: indextZO1FBVubNMeta?.alias || [],
    redirect: indextZO1FBVubNMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/ppv/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: subscriptionGK0H4nT9kSMeta?.name ?? undefined,
    path: subscriptionGK0H4nT9kSMeta?.path ?? "subscription",
    meta: subscriptionGK0H4nT9kSMeta || {},
    alias: subscriptionGK0H4nT9kSMeta?.alias || [],
    redirect: subscriptionGK0H4nT9kSMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_9346ihf0RgdGMeta?.name ?? "account-subscription-id",
    path: _91id_9346ihf0RgdGMeta?.path ?? ":id()",
    meta: _91id_9346ihf0RgdGMeta || {},
    alias: _91id_9346ihf0RgdGMeta?.alias || [],
    redirect: _91id_9346ihf0RgdGMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93aoKZ3MYhPXMeta?.name ?? "account-subscription-buy-id",
    path: _91id_93aoKZ3MYhPXMeta?.path ?? "buy/:id()",
    meta: _91id_93aoKZ3MYhPXMeta || {},
    alias: _91id_93aoKZ3MYhPXMeta?.alias || [],
    redirect: _91id_93aoKZ3MYhPXMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGncTrZ6I8mMeta?.name ?? "account-subscription",
    path: indexGncTrZ6I8mMeta?.path ?? "",
    meta: indexGncTrZ6I8mMeta || {},
    alias: indexGncTrZ6I8mMeta?.alias || [],
    redirect: indexGncTrZ6I8mMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/account/subscription/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: archiveagv6Md1HtmMeta?.name ?? "archive",
    path: archiveagv6Md1HtmMeta?.path ?? "/archive",
    meta: archiveagv6Md1HtmMeta || {},
    alias: archiveagv6Md1HtmMeta?.alias || [],
    redirect: archiveagv6Md1HtmMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: indexyCZSksEIAdMeta?.name ?? "auth-login",
    path: indexyCZSksEIAdMeta?.path ?? "/auth/login",
    meta: indexyCZSksEIAdMeta || {},
    alias: indexyCZSksEIAdMeta?.alias || [],
    redirect: indexyCZSksEIAdMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: limitNe94pxrS4sMeta?.name ?? "auth-login-limit",
    path: limitNe94pxrS4sMeta?.path ?? "/auth/login/limit",
    meta: limitNe94pxrS4sMeta || {},
    alias: limitNe94pxrS4sMeta?.alias || [],
    redirect: limitNe94pxrS4sMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/login/limit.vue").then(m => m.default || m)
  },
  {
    name: _91provider_93KO9aHETKvqMeta?.name ?? "auth-oauth2-callback-provider",
    path: _91provider_93KO9aHETKvqMeta?.path ?? "/auth/oauth2/callback/:provider()",
    meta: _91provider_93KO9aHETKvqMeta || {},
    alias: _91provider_93KO9aHETKvqMeta?.alias || [],
    redirect: _91provider_93KO9aHETKvqMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/oauth2/callback/[provider].vue").then(m => m.default || m)
  },
  {
    name: forgotPhraeHcXZBMeta?.name ?? "auth-password-forgot",
    path: forgotPhraeHcXZBMeta?.path ?? "/auth/password/forgot",
    meta: forgotPhraeHcXZBMeta || {},
    alias: forgotPhraeHcXZBMeta?.alias || [],
    redirect: forgotPhraeHcXZBMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: resetZUtNOoQCvAMeta?.name ?? "auth-password-reset",
    path: resetZUtNOoQCvAMeta?.path ?? "/auth/password/reset",
    meta: resetZUtNOoQCvAMeta || {},
    alias: resetZUtNOoQCvAMeta?.alias || [],
    redirect: resetZUtNOoQCvAMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/password/reset.vue").then(m => m.default || m)
  },
  {
    name: indexerWbk1cPT1Meta?.name ?? "auth-sign-up",
    path: indexerWbk1cPT1Meta?.path ?? "/auth/sign-up",
    meta: indexerWbk1cPT1Meta || {},
    alias: indexerWbk1cPT1Meta?.alias || [],
    redirect: indexerWbk1cPT1Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: localqFLW4jpcsnMeta?.name ?? "auth-sign-up-local",
    path: localqFLW4jpcsnMeta?.path ?? "/auth/sign-up/local",
    meta: localqFLW4jpcsnMeta || {},
    alias: localqFLW4jpcsnMeta?.alias || [],
    redirect: localqFLW4jpcsnMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/local.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sf6X36NXx7Meta?.name ?? "auth-sign-up-subscription-buy-id",
    path: _91id_93sf6X36NXx7Meta?.path ?? "/auth/sign-up/subscription/buy/:id()",
    meta: _91id_93sf6X36NXx7Meta || {},
    alias: _91id_93sf6X36NXx7Meta?.alias || [],
    redirect: _91id_93sf6X36NXx7Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/buy/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGzyaFp4lDlMeta?.name ?? "auth-sign-up-subscription-code",
    path: indexGzyaFp4lDlMeta?.path ?? "/auth/sign-up/subscription/code",
    meta: indexGzyaFp4lDlMeta || {},
    alias: indexGzyaFp4lDlMeta?.alias || [],
    redirect: indexGzyaFp4lDlMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/code/index.vue").then(m => m.default || m)
  },
  {
    name: indexzheoBa6kytMeta?.name ?? "auth-sign-up-subscription",
    path: indexzheoBa6kytMeta?.path ?? "/auth/sign-up/subscription",
    meta: indexzheoBa6kytMeta || {},
    alias: indexzheoBa6kytMeta?.alias || [],
    redirect: indexzheoBa6kytMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/auth/sign-up/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: indexzFgmX1hiL4Meta?.name ?? "category-mainId",
    path: indexzFgmX1hiL4Meta?.path ?? "/category/:mainId()",
    meta: indexzFgmX1hiL4Meta || {},
    alias: indexzFgmX1hiL4Meta?.alias || [],
    redirect: indexzFgmX1hiL4Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91subId_93boNKH4MYXtMeta?.name ?? "category-mainId-sub-category-subId",
    path: _91subId_93boNKH4MYXtMeta?.path ?? "/category/:mainId()/sub-category/:subId()",
    meta: _91subId_93boNKH4MYXtMeta || {},
    alias: _91subId_93boNKH4MYXtMeta?.alias || [],
    redirect: _91subId_93boNKH4MYXtMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/category/[mainId]/sub-category/[subId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93hpmr16FfV6Meta?.name ?? "channels-eventId",
    path: _91eventId_93hpmr16FfV6Meta?.path ?? "/channels/:eventId()",
    meta: _91eventId_93hpmr16FfV6Meta || {},
    alias: _91eventId_93hpmr16FfV6Meta?.alias || [],
    redirect: _91eventId_93hpmr16FfV6Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/channels/[eventId].vue").then(m => m.default || m)
  },
  {
    name: clubp6uHnNJs0CMeta?.name ?? "club",
    path: clubp6uHnNJs0CMeta?.path ?? "/club",
    meta: clubp6uHnNJs0CMeta || {},
    alias: clubp6uHnNJs0CMeta?.alias || [],
    redirect: clubp6uHnNJs0CMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/club.vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93Gt52yYUXLsMeta?.name ?? "events-eventId",
    path: _91eventId_93Gt52yYUXLsMeta?.path ?? "/events/:eventId()",
    meta: _91eventId_93Gt52yYUXLsMeta || {},
    alias: _91eventId_93Gt52yYUXLsMeta?.alias || [],
    redirect: _91eventId_93Gt52yYUXLsMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/[eventId].vue").then(m => m.default || m)
  },
  {
    name: _91eventId_93RH99X71Gi6Meta?.name ?? "events-stats-eventId",
    path: _91eventId_93RH99X71Gi6Meta?.path ?? "/events/stats/:eventId()",
    meta: _91eventId_93RH99X71Gi6Meta || {},
    alias: _91eventId_93RH99X71Gi6Meta?.alias || [],
    redirect: _91eventId_93RH99X71Gi6Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/events/stats/[eventId].vue").then(m => m.default || m)
  },
  {
    name: faqGSte9SsT6iMeta?.name ?? "faq",
    path: faqGSte9SsT6iMeta?.path ?? "/faq",
    meta: faqGSte9SsT6iMeta || {},
    alias: faqGSte9SsT6iMeta?.alias || [],
    redirect: faqGSte9SsT6iMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: followedVWOSJWEFvUMeta?.name ?? "following-category-mainId-followed",
    path: followedVWOSJWEFvUMeta?.path ?? "/following/category/:mainId()/followed",
    meta: followedVWOSJWEFvUMeta || {},
    alias: followedVWOSJWEFvUMeta?.alias || [],
    redirect: followedVWOSJWEFvUMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/followed.vue").then(m => m.default || m)
  },
  {
    name: indextSbAy0AAqeMeta?.name ?? "following-category-mainId",
    path: indextSbAy0AAqeMeta?.path ?? "/following/category/:mainId()",
    meta: indextSbAy0AAqeMeta || {},
    alias: indextSbAy0AAqeMeta?.alias || [],
    redirect: indextSbAy0AAqeMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/[mainId]/index.vue").then(m => m.default || m)
  },
  {
    name: followedmn0BD1umiyMeta?.name ?? "following-category-followed",
    path: followedmn0BD1umiyMeta?.path ?? "/following/category/followed",
    meta: followedmn0BD1umiyMeta || {},
    alias: followedmn0BD1umiyMeta?.alias || [],
    redirect: followedmn0BD1umiyMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/followed.vue").then(m => m.default || m)
  },
  {
    name: indexnIsWNkWcJ9Meta?.name ?? "following-category",
    path: indexnIsWNkWcJ9Meta?.path ?? "/following/category",
    meta: indexnIsWNkWcJ9Meta || {},
    alias: indexnIsWNkWcJ9Meta?.alias || [],
    redirect: indexnIsWNkWcJ9Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexBfqRWuY7wOMeta?.name ?? "following",
    path: indexBfqRWuY7wOMeta?.path ?? "/following",
    meta: indexBfqRWuY7wOMeta || {},
    alias: indexBfqRWuY7wOMeta?.alias || [],
    redirect: indexBfqRWuY7wOMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/index.vue").then(m => m.default || m)
  },
  {
    name: followedqBvXcSKB5HMeta?.name ?? "following-league-leagueId-followed",
    path: followedqBvXcSKB5HMeta?.path ?? "/following/league/:leagueId()/followed",
    meta: followedqBvXcSKB5HMeta || {},
    alias: followedqBvXcSKB5HMeta?.alias || [],
    redirect: followedqBvXcSKB5HMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/followed.vue").then(m => m.default || m)
  },
  {
    name: indexvQrLsgg37KMeta?.name ?? "following-league-leagueId",
    path: indexvQrLsgg37KMeta?.path ?? "/following/league/:leagueId()",
    meta: indexvQrLsgg37KMeta || {},
    alias: indexvQrLsgg37KMeta?.alias || [],
    redirect: indexvQrLsgg37KMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/[leagueId]/index.vue").then(m => m.default || m)
  },
  {
    name: followedFOB5uix5euMeta?.name ?? "following-league-followed",
    path: followedFOB5uix5euMeta?.path ?? "/following/league/followed",
    meta: followedFOB5uix5euMeta || {},
    alias: followedFOB5uix5euMeta?.alias || [],
    redirect: followedFOB5uix5euMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/followed.vue").then(m => m.default || m)
  },
  {
    name: indexNNtfK3bXBTMeta?.name ?? "following-league",
    path: indexNNtfK3bXBTMeta?.path ?? "/following/league",
    meta: indexNNtfK3bXBTMeta || {},
    alias: indexNNtfK3bXBTMeta?.alias || [],
    redirect: indexNNtfK3bXBTMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/following/league/index.vue").then(m => m.default || m)
  },
  {
    name: highlightQbdm5f6gTbMeta?.name ?? "highlight",
    path: highlightQbdm5f6gTbMeta?.path ?? "/highlight",
    meta: highlightQbdm5f6gTbMeta || {},
    alias: highlightQbdm5f6gTbMeta?.alias || [],
    redirect: highlightQbdm5f6gTbMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/highlight.vue").then(m => m.default || m)
  },
  {
    name: homeemZ3sW7FtDMeta?.name ?? "home",
    path: homeemZ3sW7FtDMeta?.path ?? "/home",
    meta: homeemZ3sW7FtDMeta || {},
    alias: homeemZ3sW7FtDMeta?.alias || [],
    redirect: homeemZ3sW7FtDMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexKb1RKZykycMeta?.name ?? "index",
    path: indexKb1RKZykycMeta?.path ?? "/",
    meta: indexKb1RKZykycMeta || {},
    alias: indexKb1RKZykycMeta?.alias || [],
    redirect: indexKb1RKZykycMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93111hFqEuygMeta?.name ?? "info-type",
    path: _91type_93111hFqEuygMeta?.path ?? "/info/:type()",
    meta: _91type_93111hFqEuygMeta || {},
    alias: _91type_93111hFqEuygMeta?.alias || [],
    redirect: _91type_93111hFqEuygMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/info/[type].vue").then(m => m.default || m)
  },
  {
    name: latestNK3GtIVxiZMeta?.name ?? "latest",
    path: latestNK3GtIVxiZMeta?.path ?? "/latest",
    meta: latestNK3GtIVxiZMeta || {},
    alias: latestNK3GtIVxiZMeta?.alias || [],
    redirect: latestNK3GtIVxiZMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/latest.vue").then(m => m.default || m)
  },
  {
    name: _91leagueId_93WP3xOzdbC0Meta?.name ?? "league-leagueId",
    path: _91leagueId_93WP3xOzdbC0Meta?.path ?? "/league/:leagueId()",
    meta: _91leagueId_93WP3xOzdbC0Meta || {},
    alias: _91leagueId_93WP3xOzdbC0Meta?.alias || [],
    redirect: _91leagueId_93WP3xOzdbC0Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/league/[leagueId].vue").then(m => m.default || m)
  },
  {
    name: liveNtVokPeuMlMeta?.name ?? "live",
    path: liveNtVokPeuMlMeta?.path ?? "/live",
    meta: liveNtVokPeuMlMeta || {},
    alias: liveNtVokPeuMlMeta?.alias || [],
    redirect: liveNtVokPeuMlMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/live.vue").then(m => m.default || m)
  },
  {
    name: open_45appI3ro9r9FlcMeta?.name ?? "open-app",
    path: open_45appI3ro9r9FlcMeta?.path ?? "/open-app",
    meta: open_45appI3ro9r9FlcMeta || {},
    alias: open_45appI3ro9r9FlcMeta?.alias || [],
    redirect: open_45appI3ro9r9FlcMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/open-app.vue").then(m => m.default || m)
  },
  {
    name: failureWMQwTcioRmMeta?.name ?? "payment-failure",
    path: failureWMQwTcioRmMeta?.path ?? "/payment/failure",
    meta: failureWMQwTcioRmMeta || {},
    alias: failureWMQwTcioRmMeta?.alias || [],
    redirect: failureWMQwTcioRmMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/failure.vue").then(m => m.default || m)
  },
  {
    name: processing3kizEWGEOlMeta?.name ?? "payment-processing",
    path: processing3kizEWGEOlMeta?.path ?? "/payment/processing",
    meta: processing3kizEWGEOlMeta || {},
    alias: processing3kizEWGEOlMeta?.alias || [],
    redirect: processing3kizEWGEOlMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/processing.vue").then(m => m.default || m)
  },
  {
    name: successt5K9xuFKVVMeta?.name ?? "payment-success",
    path: successt5K9xuFKVVMeta?.path ?? "/payment/success",
    meta: successt5K9xuFKVVMeta || {},
    alias: successt5K9xuFKVVMeta?.alias || [],
    redirect: successt5K9xuFKVVMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: privacyXCV1lppPu4Meta?.name ?? "privacy",
    path: privacyXCV1lppPu4Meta?.path ?? "/privacy",
    meta: privacyXCV1lppPu4Meta || {},
    alias: privacyXCV1lppPu4Meta?.alias || [],
    redirect: privacyXCV1lppPu4Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: recommendationBzugptqcd5Meta?.name ?? "recommendation",
    path: recommendationBzugptqcd5Meta?.path ?? "/recommendation",
    meta: recommendationBzugptqcd5Meta || {},
    alias: recommendationBzugptqcd5Meta?.alias || [],
    redirect: recommendationBzugptqcd5Meta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/recommendation.vue").then(m => m.default || m)
  },
  {
    name: _91referenceId_934RS158WUVoMeta?.name ?? "redirect-events-referenceId",
    path: _91referenceId_934RS158WUVoMeta?.path ?? "/redirect/events/:referenceId()",
    meta: _91referenceId_934RS158WUVoMeta || {},
    alias: _91referenceId_934RS158WUVoMeta?.alias || [],
    redirect: _91referenceId_934RS158WUVoMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/redirect/events/[referenceId].vue").then(m => m.default || m)
  },
  {
    name: search1eBKK12ZypMeta?.name ?? "search",
    path: search1eBKK12ZypMeta?.path ?? "/search",
    meta: search1eBKK12ZypMeta || {},
    alias: search1eBKK12ZypMeta?.alias || [],
    redirect: search1eBKK12ZypMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/search.vue").then(m => m.default || m)
  },
  {
    name: clips8xi9yJalyIMeta?.name ?? "selected-clips",
    path: clips8xi9yJalyIMeta?.path ?? "/selected/clips",
    meta: clips8xi9yJalyIMeta || {},
    alias: clips8xi9yJalyIMeta?.alias || [],
    redirect: clips8xi9yJalyIMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/clips.vue").then(m => m.default || m)
  },
  {
    name: videosdTfMKbypxjMeta?.name ?? "selected-videos",
    path: videosdTfMKbypxjMeta?.path ?? "/selected/videos",
    meta: videosdTfMKbypxjMeta || {},
    alias: videosdTfMKbypxjMeta?.alias || [],
    redirect: videosdTfMKbypxjMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/selected/videos.vue").then(m => m.default || m)
  },
  {
    name: suggestedkD9v2Gz7UaMeta?.name ?? "suggested",
    path: suggestedkD9v2Gz7UaMeta?.path ?? "/suggested",
    meta: suggestedkD9v2Gz7UaMeta || {},
    alias: suggestedkD9v2Gz7UaMeta?.alias || [],
    redirect: suggestedkD9v2Gz7UaMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/suggested.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93StowxngtjOMeta?.name ?? "team-teamId",
    path: _91teamId_93StowxngtjOMeta?.path ?? "/team/:teamId()",
    meta: _91teamId_93StowxngtjOMeta || {},
    alias: _91teamId_93StowxngtjOMeta?.alias || [],
    redirect: _91teamId_93StowxngtjOMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/team/[teamId].vue").then(m => m.default || m)
  },
  {
    name: termsb3M4kNwponMeta?.name ?? "terms",
    path: termsb3M4kNwponMeta?.path ?? "/terms",
    meta: termsb3M4kNwponMeta || {},
    alias: termsb3M4kNwponMeta?.alias || [],
    redirect: termsb3M4kNwponMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: upcomingwmoZ03iIERMeta?.name ?? "upcoming",
    path: upcomingwmoZ03iIERMeta?.path ?? "/upcoming",
    meta: upcomingwmoZ03iIERMeta || {},
    alias: upcomingwmoZ03iIERMeta?.alias || [],
    redirect: upcomingwmoZ03iIERMeta?.redirect,
    component: () => import("/home/runner/work/ott-web-portal2/ott-web-portal2/pages/upcoming.vue").then(m => m.default || m)
  }
]