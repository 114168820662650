import {
  formatTag,
  formatTagsCreateOption,
  formatTagsUpdateOptions,
} from "./format";
import useRequest from "~/hooks/use-request";
import useEventStore from "~/stores/event";

const TagsAPI = {
  load(options: { id: string; event: IModelEvent; global: boolean }): Promise<IModelTag[]> {
    const { request } = useRequest();
    const payload: IRequestTagsOptions = {
      global: options.global,
      page: 0,
      size: 999,
      targetId: options.id,
    };

    return request
      .post<IPaginatedResponse<IResponseTag>>("/api/tag/list", { body: payload })
      .then(response => response.content.entries.map(item => formatTag(item, options.event)));
  },
  async save(tag: IModelTag, event: IModelEvent): Promise<void> {
    const { request } = useRequest();
    const eventStore = useEventStore();
    const options: IRequestTagsCreateOptions = formatTagsCreateOption(
      tag,
      event,
    );

    return request
      .post<IContentResponse<IResponseTag>>("/api/tag/saveV2", { body: options })
      .then((response) => {
        eventStore.tags.data.set(response.content._id, formatTag(response.content, event));
      });
  },
  update(tag: IModelTag, event: IModelEvent): Promise<void> {
    const { request } = useRequest();
    const eventStore = useEventStore();
    const payload: IRequestTagsUpdateOptions = formatTagsUpdateOptions(tag);
    return request
      .post<IContentResponse<IResponseTag>>("/api/tag/update", { body: payload })
      .then((response) => {
        eventStore.tags.data.set(tag.id, formatTag(response.content, event));
      });
  },
  delete(tagId: string): Promise<void> {
    return TagsAPI.bulkDelete([tagId]);
  },
  bulkDelete(tagIds: string[]): Promise<void> {
    const { request } = useRequest();
    const eventStore = useEventStore();
    const params = { ids: tagIds };
    return request
      .post<IContentResponse<any>>("/api/tag/remove_list", { body: params })
      .then(() => tagIds.forEach(tagId => eventStore.tags.data.delete(tagId)));
  },
};

export default TagsAPI;
