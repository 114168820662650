export const formatIdentity = (item: IResponseIdentity): IModelIdentity => ({
  id: item._id,
  archived: item.archived,
  title: item.title || "NO TITLE",
  bannerUrl: item.content?.PosterF || "",
  imageUrl: item.content?.PosterH || "",
});

export const formatIdentityItem = (item: IResponseIdentityItem): IModelIdentityItem => ({
  id: item._id,
  archived: item.archived,
  title: item.title || "NO TITLE",
  imageUrl: item.content?.PosterH || "",
  bannerUrl: item.content?.PosterF,
  identityIds: item.identityId || [],
  facebookUrl: item.socialMediasLinks?.facebook,
  instagramUrl: item.socialMediasLinks?.instagram,
  xUrl: item.socialMediasLinks?.twitter,
  webUrl: item.socialMediasLinks?.web,
});

export const formatEventSubCategory = (item: IResponseIdentityItem): IModelIdentityItem => ({
  id: item._id,
  title: item.title || "NO TITLE",
  imageUrl: item.content?.PosterH || "",
  bannerUrl: item.content?.PosterF,
  identityIds: item.mediaParentIdentities ? item.mediaParentIdentities.map(formatIdentity) : [],
});

export const formatClubList = (categories: IResponseСlubList[]): IModelFavoriteClub[] => {
  const clubList = categories.reduce((result, category) => {
    const subCategories = category.relatedIdentityItems.map(subCategory => ({
      id: `${category._id}|${subCategory._id}`,
      label: subCategory.title,
      categoryTitle: category.title,
    }));
    return [...result, ...subCategories];
  }, [] as IModelFavoriteClub[]);

  return clubList;
};

export const getFavoriteClubIdFromList = (list: IResponseProfileClubList): string | null => {
  if (!list)
    return null;

  const [[key, [value]]] = Object.entries(list);

  return `${key}|${value}`;
};
