<script setup lang="ts">
import useSmartBanner from "~/composables/use-app-banner";
const { t } = useI18n();
const appConfig = useRuntimeConfig().public;
const minsSmartBannerHidden = appConfig.APP_ENV === "production" ? 60 : 2;
const { isVisible, logo, onClick, onClose } = useSmartBanner({ minsHidden: minsSmartBannerHidden });
</script>

<template>
  <div v-if="isVisible">
    <div class="bg-[var(--base-background-color)] flex gap-2 fixed top-0 p-6 min-h-10 w-full z-[150] border-t-[var(--base-header-line-color)] border-t">
      <BaseButton
        type="icon"
        name="close-banner"
        class="w-5"
        @click="onClose"
      >
        <span class="text-[var(--base-secondary-color)]">x</span>
      </BaseButton>
      <div class="flex justify-between gap-2 w-full items-center">
        <div class="flex items-center gap-2">
          <div class="min-w-[40px]">
            <BaseImage
              class="max-h-10 w-auto"
              :src="logo"
              fallback-src="/images/mock/logo.svg"
              alt="Logo"
              width="auto"
              :height="40"
            />
          </div>
          <div>
            <p class="text-[14px] break-words font-medium text-[var(--base-secondary-color)]">
              {{ t('common.get_mobile_app', { appName: appConfig.APP_CUSTOMER.name }) }}
            </p>
          </div>
        </div>

        <BaseButton
          name="Get"
          size="small"
          @click="onClick"
        >
          <div class="uppercase whitespace-pre-wrap">
            {{ t('button.get') }}
          </div>
        </BaseButton>
      </div>
    </div>
  </div>
</template>
