import type { RequestOptions } from "./fetch";
import usePxlRequest from "./fetch";
import { setAuthorizationInterceptor, setBugsnagErrorInterceptor } from "./util";
import useUserStore from "~/stores/user";

export declare interface PxlRequestOptions extends RequestOptions {
  /**
   * Token to be included in the request header for authorization purposes.
   */
  withToken?: string;

  /**
   * Flag indicating whether to force a refresh of the token for authorization.
   */
  forceRefreshToken?: boolean;

  /**
   * Flag indicating whether to set authorization headers in the request.
   */
  setAuthorization?: boolean;
}

export interface PxlRequestInterceptorContext {
  fetchOptions: RequestInit;
  requestOptions: PxlRequestOptions;
  abortController: AbortController;
}

export default function useRequest() {
  const appConfig = useRuntimeConfig().public;
  const userStore = useUserStore();

  const baseUrl = appConfig.APP_CUSTOMER.app_config?.api_base || "/";
  // const baseUrl = "https://alpha.watch.dev.pixellot.tv";
  const abortController = new AbortController();
  const onSessionExpiredError = () => {
    userStore.logout({ revokeToken: false });
    if (appConfig.APP_FLAGS.hasIfaAuth && appConfig.APP_CUSTOMER.app_config.enable_skip_login_page_for_ifa_sso)
      window.location.href = "/";

    navigateTo("/auth/login/?sessionExpired=true");
  };

  const pxlRequest = usePxlRequest({
    baseUrl,
    signal: abortController.signal,
    beforeRequest: async (
      fetchOptions: RequestInit,
      requestOptions: PxlRequestOptions,
    ) => {
      const ctx: PxlRequestInterceptorContext = {
        fetchOptions,
        requestOptions,
        abortController,
      };

      if (requestOptions.setAuthorization || requestOptions.withToken) {
        try {
          await setAuthorizationInterceptor(ctx);
        } catch (error) {
          if ((error as Error & { code?: string }).code === "refresh_token_failed")
            onSessionExpiredError();

          throw error;
        }
      }
    },
    formatResponse: (
      fetchResponse: Response,
      requestOptions: PxlRequestOptions,
    ) => {
      const responseType = requestOptions.responseType || "json";

      return fetchResponse[responseType]().then((response) => {
        if (response.error) {
          const errorMessage = "default_error_message";

          return Promise.reject(new Error(errorMessage));
        }

        return response;
      });
    },
    onError: (fetchError, _fetchOptions, requestOptions: PxlRequestOptions, retry, error) => {
      setBugsnagErrorInterceptor(fetchError);

      const hasAuth = requestOptions.setAuthorization;
      const isAuthCode = fetchError.status === 401;
      const shouldRetry = isAuthCode && hasAuth && !requestOptions.forceRefreshToken;

      if (shouldRetry)
        return retry({ forceRefreshToken: true });

      return Promise.reject(new Error(JSON.stringify({ message: error.message, code: error.code })));
    },
  });

  return pxlRequest;
}
